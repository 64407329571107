import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from "@mdx-js/react"
import { Link, withPrefix } from "gatsby"
import components from '../components/MDXComponents'
import { useSiteMetadata } from "../hooks/use-site-metadata"
import InterviewTag from "../components/interview-tag"
import TocBase from "../components/toc"
import ChartWithCategory from "../components/chart-with-category"
import { formatDate } from "../components/data-control"

export default function RemarkInterview({ data }) {
  const post = data.mdx
  const category = post.fields.category
  const { categorys } = useSiteMetadata()
  const categoryName = categorys[category]

  const Toc = ({color}) => <TocBase items={post.tableOfContents.items} color={color} />
  const shortcodes = { Toc, ...ChartWithCategory({category}), ...components }

  return (
    <Layout slug={post.fields.slug} category={category} showCharm={true} {...data.meta.nodes[0]} metaReplace={post.frontmatter}>
      <div className="p-page-title-interview">
        <div className="c-page-title-interview-data">
          <h1>
            <mark dangerouslySetInnerHTML={{__html : post.frontmatter.heading}} />
          </h1>
          <InterviewTag.Interviewee organization={post.frontmatter.organization} position={post.frontmatter.position} interviewee={post.frontmatter.interviewee} interviewee_furigana={post.frontmatter.interviewee_furigana} />
        </div>
        <img src={withPrefix(`/img/${category}/${post.fields.basename}/header.jpg`)} style={{width:"100%", height:"100%"}} alt={post.frontmatter.title} />
      </div>

      <div className="p-interview-detail">
        <MDXProvider components={shortcodes}>
          <MDXRenderer>{post.body}</MDXRenderer>
        </MDXProvider>
        <section class="p-section">
          {post.frontmatter.organization_url && (
            <React.Fragment>
              <h2 class="align-c">関連リンク</h2>
              <a class="c-button c-button-blank" href={post.frontmatter.organization_url} target="_blank">{post.frontmatter.organization}</a>
            </React.Fragment>
          )}
          <div className="c-update">{formatDate(post.frontmatter.date)} 更新</div>
        </section>
      </div>

      {data.allMdx.nodes.length > 0 && (
      <div className={`p-interview-list p-interview-list-${category}`}>
        <h2>他のインタビューも読む</h2>

        <div className="p-interview-list-items cols3">
          {data.allMdx.nodes.map((interview) => (
            <InterviewTag.Card data={{...interview.fields, ...interview.frontmatter}} />
          ))}
        </div>

        <Link to={`/${category}`} className={`c-button c-button-${category}`}>{categoryName} 一覧</Link>
      </div>
      )}
    </Layout>
  )
}

export const query = graphql`
  query($category: String!, $slug: String!, $metaSlug: String!) {
    meta: allMeta(filter: {slug: {eq: $metaSlug}}) {
      nodes {
        title
        description
        keywords
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      body
      frontmatter {
        date
        title
        heading
        description
        tags
        organization
        organization_url
        position
        interviewee
        interviewee_furigana
      }
      fields {
        slug
        category
        basename
      }
      tableOfContents(maxDepth: 3)
    }
    allMdx(
      sort: {fields: [frontmatter___date, fields___basename], order: [DESC, DESC]}
      filter: {fields: {category: {eq: $category}, slug: { ne: $slug } }}
      limit: 3
    ) {
        nodes {
          fields {
            slug
            category
            basename
          }
          frontmatter {
            title
            organization
            position
            interviewee
            date
          }
        }
      }
  }
`